// Copyright 2023 Merit International Inc. All Rights Reserved

import { DropdownMenuPressable } from "./DropdownMenuPressable";
import { DropdownMenuSectionLabel } from "./DropdownMenuSectionLabel";
import { MenuItemKind } from "./types";
import { StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useTheme } from "../../theme/useTheme";
import type { MenuItem } from "./types";
import type { NewComponentTestProps } from "../../utils/testProps";
import type { StyleProp, ViewStyle } from "react-native";

export type DropdownMenuProps = NewComponentTestProps & {
  readonly menuItems: readonly MenuItem[];
  readonly style?: StyleProp<ViewStyle>;
};

export const DropdownMenu = ({ menuItems, style, testProps }: DropdownMenuProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      borderRadius: theme.borderRadii.s,
      paddingVertical: theme.spacing.s,
    },
  });

  return (
    <View
      style={[styles.container, style]}
      {...getTestProps(
        { componentName: "DropdownMenu", testProps },
        { componentName: "DropdownMenu" }
      )}
    >
      {menuItems.map(menuItem => {
        switch (menuItem.menuItemKind) {
          case MenuItemKind.Pressable:
            return <DropdownMenuPressable menuItem={menuItem} />;
          case MenuItemKind.SectionLabel:
            return <DropdownMenuSectionLabel menuItem={menuItem} />;
          default:
            throw new Error("NotImplemented");
        }
      })}
    </View>
  );
};
