// Copyright 2022 Merit International Inc. All Rights Reserved

import addSmallDefault from "./assets/icons/add_s_default.png";
import addSmallHighlight from "./assets/icons/add_s_highlight.png";
import arrowBackwardLargeAction from "./assets/icons/arrow_backward_l_action.png";
import arrowBackwardMediumAction from "./assets/icons/arrow_backward_m_action.png";
import arrowBackwardMediumAction1 from "./assets/icons/arrow_backward_m_action-1.png";
import arrowBackwardMediumAction2 from "./assets/icons/arrow_backward_m_action-2.png";
import arrowDownwardMediumAction from "./assets/icons/arrow_downward_m_action.png";
import arrowDownwardMediumDisabled from "./assets/icons/arrow_downward_m_disabled.png";
import arrowExpandMediumDefault from "./assets/icons/arrow_expand_m_default.png";
import arrowExpandMediumHighlight from "./assets/icons/arrow_expand_m_highlight.png";
import arrowForwardMediumAction from "./assets/icons/arrow_forward_m_action.png";
import arrowUpwardExtraSmallActionSecondary from "./assets/icons/arrow_upward_xs_action_secondary.png";
import arrowUpwardMediumAction from "./assets/icons/arrow_upward_m_action.png";
import arrowUpwardMediumDisabled from "./assets/icons/arrow_upward_m_disabled.png";
import avatarCircleLarge from "./assets/icons/avatar_circle_l.png";
import avatarCircleMedium from "./assets/icons/avatar_circle_m.png";
import avatarCircleSmall from "./assets/icons/avatar_circle_s.png";
import calendarExtraSmallDefault from "./assets/icons/calendar_xs_default.png";
import calendarMediumSubdued from "./assets/icons/calendar_m_subdued.png";
import checkboxLargeSubdued from "./assets/icons/checkbox_l_subdued.png";
import checkboxSmallSubdued from "./assets/icons/checkbox_s_subdued.png";
import checkedShieldLarge from "./assets/icons/checked_shield_l.png";
import checkedShieldMedium from "./assets/icons/checked_shield_m.png";
import checkedShieldSmall from "./assets/icons/checked_shield_s.png";
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import checkedShieldOutlinedLarge from "./assets/icons/checked_shield_outlined_s.png";
import checkedShieldOutlinedMedium from "./assets/icons/checked_shield_outlined_m.png";
import checkedShieldOutlinedSmall from "./assets/icons/checked_shield_outlined_l.png";
import checkmarkExtraSmallAction from "./assets/icons/checkmark_xs_action.png";
import checkmarkExtraSmallDisabled from "./assets/icons/checkmark_xs_subdued.png";
import checkmarkSmallAction from "./assets/icons/checkmark_s_action.png";
import checkmarkSmallDisabled from "./assets/icons/checkmark_s_subdued.png";
import checkmarkSmallSuccess from "./assets/icons/checkmark_s_success.png";
import checkmarkSuccess from "./assets/icons/checkmark_success.png";
import checkoutMediumAction from "./assets/icons/check_out_m_action.png";
import checkoutMediumDefault from "./assets/icons/check_out_m_default.png";
import chevronDownCircleLargeDefault from "./assets/icons/chevron_down_circle_l_default.png";
import chevronDownExtraSmallAction from "./assets/icons/chevron_down_xs_action.png";
import chevronDownExtraSmallDefault from "./assets/icons/chevron_down_xs_default.png";
import chevronDownMediumDefault from "./assets/icons/chevron_down_m_default.png";
import chevronDownSmallAction from "./assets/icons/chevron_down_s_action.png";
import chevronDownSmallDefault from "./assets/icons/chevron_down_s_default.png";
import chevronDownSmallDisabled from "./assets/icons/chevron_down_s_disabled.png";
import chevronDownSmallSubdued from "./assets/icons/chevron_down_s_subdued.png";
import chevronLeftMediumAction from "./assets/icons/chevron_left_m_action.png";
import chevronLeftMediumAction1 from "./assets/icons/chevron_left_m_action-1.png";
import chevronLeftMediumDisabled from "./assets/icons/chevron_left_m_subdued.png";
import chevronRightMediumAction from "./assets/icons/chevron_right_m_action.png";
import chevronRightMediumDisabled from "./assets/icons/chevron_right_m_subdued.png";
import chevronUpMediumDefault from "./assets/icons/chevron_up_m_default.png";
import clockMediumAction from "./assets/icons/clock_m_action.png";
import closeCircleMediumDefault from "./assets/icons/close_circle_m_default.png";
import closeExtraSmallDefault from "./assets/icons/close_xs_default.png";
import closeExtraSmallDisabled from "./assets/icons/close_xs_subdued.png";
import closeLargeAction from "./assets/icons/close_l_action.png";
import closeLargeDisabled from "./assets/icons/close_l_subdued.png";
import closeMediumAction from "./assets/icons/close_m_action.png";
import closeMediumActionSecondary from "./assets/icons/close_m_action_secondary.png";
import closeSmallDefault from "./assets/icons/close_s_default.png";
import closeSmallDisabled from "./assets/icons/close_s_subdued.png";
import codeSmallDefault from "./assets/icons/code_s_default.png";
import defaultOrg from "./assets/icons/default_org.png";
import defaultOrgDesktop from "./assets/icons/default_org_desktop.png";
import defaultOrgDesktopNotify from "./assets/icons/default_org_desktop_notify.png";
import documentMediumDefault from "./assets/icons/document_m_default.png";
import documentMediumDisabled from "./assets/icons/document_m_subdued.png";
import documentMediumLink from "./assets/icons/document_m_link.png";
import editMediumLink from "./assets/icons/edit_m_link.png";
import editSmallAction from "./assets/icons/edit_s_action.png";
import errorLargeDefault from "./assets/icons/error_l_default.png";
import errorMediumWarning from "./assets/icons/error_m_warning.png";
import filterMediumAction from "./assets/icons/filter_m_action.png";
import gearOutlinedLarge from "./assets/icons/gear_outlined_l.png";
import gearOutlinedMedium from "./assets/icons/gear_outlined_m.png";
import gearOutlinedSmall from "./assets/icons/gear_outlined_s.png";
import globeMediumSubdued from "./assets/icons/globe_m_subdued.png";
import headingOneSmallDefault from "./assets/icons/heading_one_s_default.png";
import headingTwoSmallDefault from "./assets/icons/heading_two_s_default.png";
import helpOutlinedMediumDefault from "./assets/icons/help_outlined_m_default.png";
import homeLargeActive from "./assets/icons/home_l_active.png";
import homeLargeDefault from "./assets/icons/home_l_default.png";
import homeMediumDefault from "./assets/icons/home_m_default.png";
import idCardLargeActive from "./assets/icons/id_card_l_active.png";
import idCardLargeDefault from "./assets/icons/id_card_l_default.png";
import informationActive from "./assets/icons/information_active.png";
import informationInactive from "./assets/icons/information_inactive.png";
import informationMediumHighlight from "./assets/icons/information_m_highlight.png";
import informationOutlinedMediumAction from "./assets/icons/information_outlined_m_action.png";
import informationOutlinedMediumDefault from "./assets/icons/information_outlined_m_default.png";
import inheritedFieldMediumDefault from "./assets/icons/inherited_field_m_default.png";
import ipadLargeDefault from "./assets/icons/ipad_l_default.png";
import ipadMediumDefault from "./assets/icons/ipad_m_default.png";
import italicSmallDefault from "./assets/icons/italic_s_default.png";
import layoutMediumDefault from "./assets/icons/layout_m_default.png";
import linkMediumHighlight from "./assets/icons/link_m_highlight.png";
import listBulletsSmallDefault from "./assets/icons/list_bullets_s_default.png";
import listNumbersSmallDefault from "./assets/icons/list_numbers_s_default.png";
import loadingSpinnerMediumAction from "./assets/icons/loading_spinner_m_action.png";
import locationLargeDefault from "./assets/icons/location_l_default.png";
import locationMediumAction from "./assets/icons/location_m_action.png";
import locationMediumDefault from "./assets/icons/location_m_default.png";
import locationSmallSubdued from "./assets/icons/location_s_subdued.png";
import lockLargeAction from "./assets/icons/lock_l_action.png";
import logoutMediumDefault from "./assets/icons/logout_m_default.png";
import mappedFieldMediumHighlight from "./assets/icons/mapped_field_m_highlight.png";
import menuLeft from "./assets/icons/menu_left.png";
import menuVerticalMediumDefault from "./assets/icons/menu_vertical_m_default.png";
import merit from "./assets/icons/merit.png";
import meritOutlinedLarge from "./assets/icons/merit_outlined_l.png";
import meritOutlinedLargeAction from "./assets/icons/merit_outlined_l_action.png";
import meritOutlinedMedium from "./assets/icons/merit_outlined_m.png";
import meritOutlinedSmall from "./assets/icons/merit_outlined_s.png";
import noPersonasLarge from "./assets/icons/no_personas_l.png";
import noPersonasMedium from "./assets/icons/no_personas_m.png";
import noPersonasSmall from "./assets/icons/no_personas_s.png";
import notificationDesktop from "./assets/icons/notification_desktop.png";
import notificationDesktopEmpty from "./assets/icons/notification_desktop_empty.png";
import offlineMediumDefault from "./assets/icons/offline_m_default.png";
import profileMediumDefault from "./assets/icons/profile_m_default.png";
import programmingModuleLargeAction from "./assets/icons/programming_module_l_action.png";
import qrCodeLargeAction from "./assets/icons/qr_code_l_action.png";
import quotationSmallDefault from "./assets/icons/quotation_s_default.png";
import refreshMediumAction from "./assets/icons/refresh_m_action.png";
import removeCircleMediumCritical from "./assets/icons/remove_circle_m_critical.png";
import removeExtraSmallDefault from "./assets/icons/remove_xs_default.png";
import removeOutlined from "./assets/icons/remove_outlined.png";
import removeSmallAction from "./assets/icons/remove_s_action.png";
import searchSmallSubdued from "./assets/icons/search_s_subdued.png";
import sendMediumDefault from "./assets/icons/send_m_default.png";
import shieldMediumDefault from "./assets/icons/shield_m_default.png";
import starMediumDefault from "./assets/icons/star_m_default.png";
import starMediumSixRed from "./assets/icons/star_m_six_red.png";
import starSmallRed from "./assets/icons/star_s_red.png";
import successMediumSuccess from "./assets/icons/success_m_success.png";
import switchMediumDefault from "./assets/icons/switch_m_default.png";
import textBoldSmallDefault from "./assets/icons/text_bold_s_default.png";
import textUnderlineSmallDefault from "./assets/icons/text_underline_s_default.png";
import timeLargeDefault from "./assets/icons/time_l_default.png";
import timeMediumSubdued from "./assets/icons/time_m_subdued.png";
import trash from "./assets/icons/trash.png";
import unlinkLargeDefault from "./assets/icons/unlink_l_default.png";
import userAddMediumAction from "./assets/icons/user_add_m_action.png";
import userAddMediumDefault from "./assets/icons/user_add_m_default.png";
import userCircleMediumAction from "./assets/icons/user_circle_m_action.png";
import verifiedMeritLargeDefault from "./assets/icons/verified_merit_l_default.png";
import verifiedMeritMediumDefault from "./assets/icons/verified_merit_m_default.png";
import verifiedMeritMediumDefault1 from "./assets/icons/verified_merit_m_default-1.png";
import viewMediumDefault from "./assets/icons/view_m_default.png";
import viewSmallAction from "./assets/icons/view_s_action.png";
import warningLarge from "./assets/icons/warning_l.png";
import warningMediumCritical from "./assets/icons/warning_m_critical.png";

export const Icons = {
  addSmallDefault,
  addSmallHighlight,
  arrowBackwardLargeAction,
  arrowBackwardMediumAction,
  arrowBackwardMediumAction1,
  arrowBackwardMediumAction2,
  arrowDownwardMediumAction,
  arrowDownwardMediumDisabled,
  arrowExpandMediumDefault,
  arrowExpandMediumHighlight,
  arrowForwardMediumAction,
  arrowUpwardExtraSmallActionSecondary,
  arrowUpwardMediumAction,
  arrowUpwardMediumDisabled,
  avatarCircleLarge,
  avatarCircleMedium,
  avatarCircleSmall,
  calendarExtraSmallDefault,
  calendarMediumSubdued,
  checkboxLargeSubdued,
  checkboxSmallSubdued,
  checkedShieldLarge,
  checkedShieldMedium,
  checkedShieldSmall,
  checkmarkExtraSmallDisabled,
  checkmarkSmallDisabled,
  chevronLeftMediumDisabled,
  chevronRightMediumDisabled,
  closeExtraSmallDisabled,
  closeLargeDisabled,
  closeSmallDisabled,
  documentMediumDisabled,
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  checkedShieldOutlinedLarge,
  checkedShieldOutlinedMedium,
  checkedShieldOutlinedSmall,
  checkmarkExtraSmallAction,
  checkmarkSmallAction,
  checkmarkSmallSuccess,
  checkmarkSuccess,
  checkoutMediumAction,
  checkoutMediumDefault,
  chevronDownCircleLargeDefault,
  chevronDownExtraSmallAction,
  chevronDownExtraSmallDefault,
  chevronDownMediumDefault,
  chevronDownSmallAction,
  chevronDownSmallDefault,
  chevronDownSmallDisabled,
  chevronDownSmallSubdued,
  chevronLeftMediumAction,
  chevronLeftMediumAction1,
  chevronRightMediumAction,
  chevronUpMediumDefault,
  clockMediumAction,
  closeCircleMediumDefault,
  closeExtraSmallDefault,
  closeLargeAction,
  closeMediumAction,
  closeMediumActionSecondary,
  closeSmallDefault,
  codeSmallDefault,
  defaultOrg,
  defaultOrgDesktop,
  defaultOrgDesktopNotify,
  documentMediumDefault,
  documentMediumLink,
  editMediumLink,
  editSmallAction,
  errorLargeDefault,
  errorMediumWarning,
  filterMediumAction,
  gearOutlinedLarge,
  gearOutlinedMedium,
  gearOutlinedSmall,
  globeMediumSubdued,
  headingOneSmallDefault,
  headingTwoSmallDefault,
  helpOutlinedMediumDefault,
  homeLargeActive,
  homeLargeDefault,
  homeMediumDefault,
  idCardLargeActive,
  idCardLargeDefault,
  informationActive,
  informationInactive,
  informationMediumHighlight,
  informationOutlinedMediumAction,
  informationOutlinedMediumDefault,
  inheritedFieldMediumDefault,
  ipadLargeDefault,
  ipadMediumDefault,
  italicSmallDefault,
  layoutMediumDefault,
  linkMediumHighlight,
  listBulletsSmallDefault,
  listNumbersSmallDefault,
  loadingSpinnerMediumAction,
  locationLargeDefault,
  locationMediumAction,
  locationMediumDefault,
  locationSmallSubdued,
  lockLargeAction,
  logoutMediumDefault,
  mappedFieldMediumHighlight,
  menuLeft,
  menuVerticalMediumDefault,
  merit,
  meritOutlinedLarge,
  meritOutlinedLargeAction,
  meritOutlinedMedium,
  meritOutlinedSmall,
  noPersonasLarge,
  noPersonasMedium,
  noPersonasSmall,
  notificationDesktop,
  notificationDesktopEmpty,
  offlineMediumDefault,
  profileMediumDefault,
  programmingModuleLargeAction,
  qrCodeLargeAction,
  quotationSmallDefault,
  refreshMediumAction,
  removeCircleMediumCritical,
  removeExtraSmallDefault,
  removeOutlined,
  removeSmallAction,
  searchSmallSubdued,
  sendMediumDefault,
  shieldMediumDefault,
  starMediumDefault,
  starMediumSixRed,
  starSmallRed,
  successMediumSuccess,
  switchMediumDefault,
  textBoldSmallDefault,
  textUnderlineSmallDefault,
  timeLargeDefault,
  timeMediumSubdued,
  trash,
  unlinkLargeDefault,
  userAddMediumAction,
  userAddMediumDefault,
  userCircleMediumAction,
  verifiedMeritLargeDefault,
  verifiedMeritMediumDefault,
  verifiedMeritMediumDefault1,
  viewMediumDefault,
  viewSmallAction,
  warningLarge,
  warningMediumCritical,
};
